import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TextResult } from '../types/types';

export interface GameState {
  votes: {
    done: number;
    total: number;
  };
  extraVotes: {
    done: number;
    total: number;
  };
  bets: {
    done: number;
    total: number;
  };
  nominations: {
    done: number;
    total: number;
  };
  results: TextResult[];
}

const initialState: GameState = {
  votes: {
    done: 0,
    total: 0,
  },
  extraVotes: {
    done: 0,
    total: 0,
  },
  bets: {
    done: 0,
    total: 0,
  },
  nominations: {
    done: 0,
    total: 0,
  },
  results: [],
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setPlayers: (state, action: PayloadAction<GameState>) => {
      state.votes = action.payload.votes;
      state.extraVotes = action.payload.extraVotes;
      state.nominations = action.payload.nominations;
      state.bets = action.payload.bets;
      state.results = action.payload.results;
    },
  },
});
