var options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  timezone: 'UTC',
};

export function localizeDate(date: Date) {
  // @ts-ignore
  return date.toLocaleString('ru', options);
}

export function getDaysDiff(dateStart: Date, dateEnd: Date) {
  return Math.ceil((dateEnd.valueOf() - dateStart.valueOf()) / 1000 / 60 / 60 / 24);
}
