import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Text } from '../types/types';

export interface TextsState {
  list: Text[];
}

const initialState: TextsState = {
  list: [],
};

export const textsSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<TextsState['list']>) => {
      state.list = action.payload;
    },
  },
});
