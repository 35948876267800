import { ExtraVoteBlock } from './components/ExtraVoteBlock';
import style from './Rules.module.scss';
import { NominationBlock } from './components/NominationBlock';
import { achievementsList, nominationsList } from '../../../data/nominations';
import { RulesBase } from './RulesBase';

interface Props {}

export const Rules = (props: Props) => {
  const extraVoteMock = {
    like: {
      bad: 4,
      neutral: 2,
      good: 1,
    },
    theme: {
      bad: 4,
      neutral: 2,
      good: 1,
    },
    readable: {
      bad: 4,
      neutral: 2,
      good: 1,
    },
    style: {
      bad: 4,
      neutral: 2,
      good: 1,
    },
  };

  return (
    <>
      <div className={style.rules}>
        <RulesBase />

        <h3>Дополнительные оценки</h3>

        <div className={style.line}>
          Есть 4 вида шкал для дополнительных оценок:Понравился ли текст в целом? Хорошо ли раскрыта тема? Легко ли читается текст? Понравился ли стиль текста?
        </div>

        <div className={style.line}>Все они будут отражены в результатах в виде конкретного количества и процентного соотношения</div>
        <div className={style.line}>Также в конце игры будет показано 4 дополнительных рейтинга на основе этих критериев</div>

        <div className={style.grid4}>
          <ExtraVoteBlock type="like" extraVotes={extraVoteMock} />
          <ExtraVoteBlock type="theme" extraVotes={extraVoteMock} />
          <ExtraVoteBlock type="readable" extraVotes={extraVoteMock} />
          <ExtraVoteBlock type="style" extraVotes={extraVoteMock} />
        </div>

        <h3>Номинации</h3>

        <div className={style.line}>
          В игре есть {nominationsList.length} видов номинаций. Ты можешь выдать номинацию для любого текста. Можно дать одному тексту несколько номинаций, но
          нельзя дать несколько одинаковых номинаций.
        </div>
        <div className={style.nominations}>
          {nominationsList.map((nomination, index) => {
            return <NominationBlock key={index} item={nomination} count={0} />;
          })}
        </div>

        <h3>Ачивки</h3>

        <div className={style.line}>
          Также есть {achievementsList.length} дополнительных ачивок, которые можно получить по результатам всех игр. Их можно увидеть в своём профиле и на
          странице "Зал славы".
        </div>
        <div className={style.nominations}>
          {achievementsList.map((nomination, index) => {
            return <NominationBlock key={index} item={nomination} count={0} />;
          })}
        </div>

        <h3>Ставки</h3>

        <div className={style.line}>
          Можно поставить пчелокоины 🐝 и арбузокоины 🍉 на тексты, которые ты считаешь фаворитами и возможными победителями. Если текст займёт 1 место, то
          выигрыш составит х3, если 2 место — х2, если третье — х1. Ставки на все места ниже сжигают сумму.
        </div>
      </div>
    </>
  );
};
