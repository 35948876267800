import React from 'react';
import { ProfileState } from '../../../store/profile.slice';
import { ActionType, Text } from '../../../types/types';
import style from './Nominations.module.scss';
import { postNominationApi } from '../../../api/user.api';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { nominationsList, NominationItem } from '../../../data/nominations';
import { getProfileMethod } from '../../../methods/app.methods';

interface Props {
  texts: Text[];
  profile: ProfileState;
}

interface NominationProps {
  nomination: NominationItem;
  texts: Text[];
  isAlreadySelectedTextIndex: number;
}

const Nomination = (props: NominationProps) => {
  let { guid = '' } = useParams();
  const { nomination } = props;
  const [isChosenTextIndex, set_isChosenTextIndex] = React.useState(props.isAlreadySelectedTextIndex !== -1 ? props.isAlreadySelectedTextIndex : -1);
  const [textIndex, set_textIndex] = React.useState<string>('0');
  const [isReadyToSave, set_isReadyToSave] = React.useState(false);

  function onChangeOption(value: string) {
    if (value === '0') {
      set_isReadyToSave(false);
      set_textIndex('0');
    } else {
      set_isReadyToSave(true);
      set_textIndex(value);
    }
  }

  function onSelectNomination() {
    postNominationApi({
      guid,
      textIndex: parseInt(textIndex),
      type: nomination.type,
    })
      .then(() => {
        set_isChosenTextIndex(parseInt(textIndex));
        getProfileMethod(guid);
      })
      .catch(() => {
        alert('Не удалось выдать номинацию');
      });
  }

  return (
    <div
      className={cn(style.nomination, {
        [style.selected]: isChosenTextIndex > 0,
      })}
    >
      <div className={style.title}>«{nomination.title}»</div>
      <div className={style.description}>{nomination.description}</div>
      {isChosenTextIndex === -1 && (
        <>
          <select className={style.select} onChange={(event) => onChangeOption(event.target.value)}>
            <option value={0}>❌ Не выбрано</option>
            {props.texts.map((text, index2) => {
              if (text.isMine) return null;
              return (
                <option key={index2} value={index2 + 1}>
                  {text.title}
                </option>
              );
            })}
          </select>
          {isReadyToSave && (
            <>
              <button onClick={onSelectNomination} className={style.button}>
                Выдать номинацию
              </button>
              <span className={style.buttonInformer}>Это действие нельзя будет отменить</span>
            </>
          )}
        </>
      )}
      {isChosenTextIndex > -1 && (
        <>
          <div>
            ✅ Выбрана для текста <strong>«{props.texts[isChosenTextIndex - 1].title}»</strong>
          </div>
        </>
      )}
    </div>
  );
};

export const Nominations = (props: Props) => {
  return (
    <div className={style.nominations}>
      {nominationsList.map((nomination, index) => {
        const isAlreadySelectedText = props.profile.nominations.find((_nomination) => _nomination.type === nomination.type);
        const isAlreadySelectedTextIndex = isAlreadySelectedText ? isAlreadySelectedText.textIndex : -1;
        return <Nomination key={index} texts={props.texts} nomination={nomination} isAlreadySelectedTextIndex={isAlreadySelectedTextIndex} />;
      })}
    </div>
  );
};
