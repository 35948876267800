import { pluralSets, pluralText } from '../../../methods/text.methods';
import { BetsState } from '../../../store/bets.slice';
import style from './BetsResults.module.scss';
import cn from 'classnames';

interface Props {
  bets: BetsState;
}

export const BetsResults = (props: Props) => {
  const betsTotal = {
    total: {
      beecoin: props.bets.results.reduce((acc, bet) => {
        if (bet.coin === 'beecoin') {
          return acc + bet.amount;
        } else {
          return acc;
        }
      }, 0),
      watermeloncoin: props.bets.results.reduce((acc, bet) => {
        if (bet.coin === 'watermeloncoin') {
          return acc + bet.amount;
        } else {
          return acc;
        }
      }, 0),
    },
    win: {
      beecoin: props.bets.results.reduce((acc, bet) => {
        if (bet.coin === 'beecoin' && bet.factor > 0) {
          return acc + bet.amount * bet.factor;
        } else {
          return acc;
        }
      }, 0),
      watermeloncoin: props.bets.results.reduce((acc, bet) => {
        if (bet.coin === 'watermeloncoin' && bet.factor > 0) {
          return acc + bet.amount * bet.factor;
        } else {
          return acc;
        }
      }, 0),
    },
    lose: {
      beecoin: props.bets.results.reduce((acc, bet) => {
        if (bet.coin === 'beecoin' && bet.factor === 0) {
          return acc + bet.amount;
        } else {
          return acc;
        }
      }, 0),
      watermeloncoin: props.bets.results.reduce((acc, bet) => {
        if (bet.coin === 'watermeloncoin' && bet.factor === 0) {
          return acc + bet.amount;
        } else {
          return acc;
        }
      }, 0),
    },
  };
  return (
    <div className={style.bets}>
      <div className={style.total}>
        <div className={style.totalBlock}>
          <h2>Поставлено</h2>
          <div className={style.totalCoins}>
            <div>
              <h3>🐝</h3>
              <div>{betsTotal.total.beecoin}</div>
            </div>
            <div>
              <h3>🍉</h3>
              <div>{betsTotal.total.watermeloncoin}</div>
            </div>
          </div>
        </div>

        <div className={style.totalBlock}>
          <h2>Выиграно</h2>
          <div className={style.totalCoins}>
            <div>
              <h3>🐝</h3>
              <div>{betsTotal.win.beecoin}</div>
            </div>
            <div>
              <h3>🍉</h3>
              <div>{betsTotal.win.watermeloncoin}</div>
            </div>
          </div>
        </div>

        <div className={style.totalBlock}>
          <h2>Проиграно</h2>
          <div className={style.totalCoins}>
            <div>
              <h3>🐝</h3>
              <div>{betsTotal.lose.beecoin}</div>
            </div>
            <div>
              <h3>🍉</h3>
              <div>{betsTotal.lose.watermeloncoin}</div>
            </div>
          </div>
        </div>
      </div>

      {props.bets.results.map((bet, index) => {
        const pluralType = bet.coin === 'beecoin' ? pluralSets.beecoin : pluralSets.watermeloncoin;
        const coinIcon = bet.coin === 'beecoin' ? '🐝' : '🍉';

        return (
          <div
            className={cn(style.betRow, {
              [style.greenLine]: bet.factor > 0,
              [style.redLine]: bet.factor === 0,
            })}
            key={index}
          >
            <div>{bet.userName}</div>
            <div>
              поставил(а) {pluralText(bet.amount, pluralType)} {coinIcon} на текст «{bet.title}»
            </div>
            <div>
              {bet.factor === 3 && (
                <div>
                  Выигрыш х3 (+{bet.amount * bet.factor} {coinIcon})
                </div>
              )}
              {bet.factor === 2 && (
                <div>
                  Выигрыш х2 (+{bet.amount * bet.factor} {coinIcon})
                </div>
              )}
              {bet.factor === 1 && (
                <div>
                  Выигрыш х1 (+{bet.amount * bet.factor} {coinIcon})
                </div>
              )}
              {bet.factor === 0 && <div className={style.red}>Результат убил 💀</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};
