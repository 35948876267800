// Выбор темы
export const date_theme_start = new Date('November 16, 2024 00:00:00');
export const date_theme_end = new Date('November 17, 2024 23:59:59');

// Написание текстов
export const date_texts_start = new Date('November 18, 2024 00:00:00');
export const date_texts_end = new Date('November 27, 2024 23:59:59');

// Голосование
export const date_voting_start = new Date('November 28, 2024 00:00:00');
export const date_voting_end = new Date('December 1, 2024 23:59:59');

// Результаты
export const date_results = new Date('December 2, 2024 00:00:00');
