import { useParams } from 'react-router-dom';
import { ProfileState } from '../../../store/profile.slice';
import { Text } from '../../../types/types';
import style from './Guess.module.scss';
import React from 'react';
import cn from 'classnames';
import { fameUsers } from '../../../data/users';
import { postGuessApi } from '../../../api/user.api';
import { getProfileMethod } from '../../../methods/app.methods';

interface GuessProps {
  text: Text;
  textNN: number;
  myName: string;
  isAlreadySelectedUserName: string;
  isAlreadySelectedUserSame: number;
  isAlreadySelectedUserTotal: number;
}

const GuessBlock = (props: GuessProps) => {
  let { guid = '' } = useParams();
  const usersForSelect = fameUsers.filter((user) => user.round5[0] === 'Участвует');

  const userByNameIndex = usersForSelect.findIndex((user) => user.name === props.isAlreadySelectedUserName);
  const [isChosenUserIndex, set_isChosenUserIndex] = React.useState(userByNameIndex !== -1 ? userByNameIndex : -1);
  const [userIndex, set_userIndex] = React.useState<string>('0');
  const [isReadyToSave, set_isReadyToSave] = React.useState(false);

  function onChangeOption(value: string) {
    if (value === '0') {
      set_isReadyToSave(false);
      set_userIndex('0');
    } else {
      set_isReadyToSave(true);
      set_userIndex(value);
    }
  }

  function onSelectGuess() {
    const user = usersForSelect[parseInt(userIndex) - 1];
    if (!user) return;

    postGuessApi({
      guid,
      textIndex: props.textNN,
      userName: user.name,
    })
      .then(() => {
        set_isChosenUserIndex(parseInt(userIndex) - 1);
        getProfileMethod(guid);
      })
      .catch(() => {
        alert('Не удалось выставить догадку');
      });
  }

  const samePercent = Math.ceil((props.isAlreadySelectedUserSame / props.isAlreadySelectedUserTotal) * 100);

  return (
    <div
      className={cn(style.guess, {
        [style.selected]: isChosenUserIndex !== -1,
      })}
    >
      <div className={style.title}>Кто написал «{props.text.title}»?</div>
      {isChosenUserIndex === -1 && (
        <>
          <select className={style.select} onChange={(event) => onChangeOption(event.target.value)}>
            <option value={0}>❌ Не выбрано</option>
            {usersForSelect.map((user, index2) => {
              if (user.name === props.myName) return null;
              return (
                <option key={index2} value={index2 + 1}>
                  {user.name}
                </option>
              );
            })}
          </select>
          {isReadyToSave && (
            <>
              <button onClick={onSelectGuess} className={style.button}>
                Отправить догадку
              </button>
              <span className={style.buttonInformer}>Это действие нельзя будет отменить</span>
            </>
          )}
        </>
      )}
      {isChosenUserIndex > -1 && (
        <>
          <div>
            ✅ Ты думаешь, что это{' '}
            <strong>
              «{usersForSelect[isChosenUserIndex].name}», и так же думают {props.isAlreadySelectedUserSame} из {props.isAlreadySelectedUserTotal} игроков (
              {samePercent}%)
            </strong>
          </div>
        </>
      )}
    </div>
  );
};

interface Props {
  texts: Text[];
  profile: ProfileState;
}

export const Guesses = (props: Props) => {
  let { guid = '' } = useParams();
  function refreshProfile() {
    getProfileMethod(guid);
  }

  return (
    <div className={style.guess}>
      <h2>Угадай-ка</h2>
      <button onClick={refreshProfile} className={style.buttonRefresh}>
        Обновить счётчики
      </button>
      {props.texts.map((text, index) => {
        const isAlreadySelectedUser = props.profile.guesses.find((_guess) => _guess.textIndex === index + 1);
        const isAlreadySelectedUserName = isAlreadySelectedUser ? isAlreadySelectedUser.userName : '???';
        const isAlreadySelectedUserSame = isAlreadySelectedUser ? isAlreadySelectedUser.samePlayers : 1;
        const isAlreadySelectedUserTotal = isAlreadySelectedUser ? isAlreadySelectedUser.totalPlayers : 1;
        if (text.isMine) return null;
        return (
          <GuessBlock
            key={index}
            isAlreadySelectedUserName={isAlreadySelectedUserName}
            isAlreadySelectedUserSame={isAlreadySelectedUserSame}
            isAlreadySelectedUserTotal={isAlreadySelectedUserTotal}
            text={text}
            textNN={index + 1}
            myName={props.profile.realName}
          />
        );
      })}
    </div>
  );
};
