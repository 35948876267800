import React from 'react';

interface Props {}

export const Settings = (props: Props) => {
  var storedTheme = localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
  var storedScreen = localStorage.getItem('screen') || 'medium';

  const [theme, set_theme] = React.useState(storedTheme);
  const [screen, set_screen] = React.useState(storedScreen);

  function toggleTheme(theme: string) {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
    set_theme(theme);
  }

  function toggleScreen(size: string) {
    document.documentElement.setAttribute('data-screen', size);
    localStorage.setItem('screen', size);
    set_screen(size);
  }

  return (
    <>
      <div className="settings">
        <div className="settingsBlock">
          <h3>Тема</h3>
          <div className="buttons">
            <div className="buttonWrapper">
              <button className="light" onClick={() => toggleTheme('light')}>
                светлая
              </button>
              <div className="text">{theme === 'light' && 'Выбрано'} </div>
            </div>
            <div className="buttonWrapper">
              <button className="dark" onClick={() => toggleTheme('dark')}>
                тёмная
              </button>
              <div className="text">{theme === 'dark' && 'Выбрано'} </div>
            </div>
            <div className="buttonWrapper">
              <button className="bee" onClick={() => toggleTheme('bee')}>
                пчола
              </button>
              <div className="text">{theme === 'bee' && 'Выбрано'} </div>
            </div>
            <div className="buttonWrapper">
              <button className="watermelon" onClick={() => toggleTheme('watermelon')}>
                арбуз
              </button>
              <div className="text">{theme === 'watermelon' && 'Выбрано'} </div>
            </div>
          </div>
        </div>

        <div className="settingsBlock">
          <h3>Ширина экрана</h3>
          <div className="buttons">
            <div className="buttonWrapper">
              <button className="dark" onClick={() => toggleScreen('thin')}>
                узкий (960)
              </button>
              <div className="text">{screen === 'thin' && 'Выбрано'} </div>
            </div>
            <div className="buttonWrapper">
              <button className="dark" onClick={() => toggleScreen('medium')}>
                обычный (1200)
              </button>
              <div className="text">{screen === 'medium' && 'Выбрано'} </div>
            </div>
            <div className="buttonWrapper">
              <button className="dark" onClick={() => toggleScreen('wide')}>
                широкий (1600)
              </button>
              <div className="text">{screen === 'wide' && 'Выбрано'} </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="text">
            Вжух!
            <br />
            Заходи{' '}
            <a href="https://shining-project.ru/" target="_blank" rel="noreferrer">
              на огонёк
            </a>
          </div>
          <img src="/img/iyra.png" alt="" />
        </div>
      </div>
    </>
  );
};
