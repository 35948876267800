import style from './RulesBase.module.scss';
import { date_results, date_texts_end, date_texts_start, date_theme_end, date_theme_start, date_voting_end, date_voting_start } from '../../../data/gameConfig';
import { getDaysDiff, localizeDate } from '../../../methods/date.methods';
import { pluralSets, pluralText } from './../../../methods/text.methods';

interface Props {}

export const RulesBase = (props: Props) => {
  const theme_start = localizeDate(date_theme_start);
  const theme_end = localizeDate(date_theme_end);
  const theme_diff = getDaysDiff(date_theme_start, date_theme_end);

  const texts_start = localizeDate(date_texts_start);
  const texts_end = localizeDate(date_texts_end);
  const texts_diff = getDaysDiff(date_texts_start, date_texts_end);

  const voting_start = localizeDate(date_voting_start);
  const voting_end = localizeDate(date_voting_end);
  const voting_diff = getDaysDiff(date_voting_start, date_voting_end);

  const results_start = localizeDate(date_results);

  return (
    <>
      <div className={style.rulesBase}>
        <h3>А что это?</h3>

        <div className={style.line}>
          "Грелочка" — это наш маленький проект, основанный на известном в некоторых кругах конкурсе рассказов "Рваная грелка". Мы взяли основные идеи и
          переоформили в более компактный размер для 20-30 участников, многие из которых уже стали постоянными
        </div>

        <div className={style.line}>
          Мы собираемся все вместе и пишем небольшие рассказы по своим сеттингам по заранее выбранной теме. Важный момент — наличие телеграма, так как тексты
          нужно сдавать при помощи бота для обеспечения анонимности и проведения голосования
        </div>

        <h3>А когда?</h3>

        <table>
          <thead>
            <tr>
              <td>Когда</td>
              <td>Сколько</td>
              <td>Что делаем</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {theme_start} — {theme_end}
              </td>
              <td>{pluralText(theme_diff, pluralSets.days)}</td>
              <td>Выбор темы</td>
            </tr>
            <tr>
              <td>
                {texts_start} — {texts_end}
              </td>
              <td>{pluralText(texts_diff, pluralSets.days)}</td>
              <td>Написание текстов</td>
            </tr>
            <tr>
              <td>
                {voting_start} — {voting_end}
              </td>
              <td>{pluralText(voting_diff, pluralSets.days)}</td>
              <td>Чтение и голосование</td>
            </tr>
            <tr>
              <td>{results_start}</td>
              <td></td>
              <td>Результаты</td>
            </tr>
          </tbody>
        </table>

        <h3>А как?</h3>

        <div className={style.line}>
          Тексты сдаются через бота в телеграме общедоступной ссылкой на гугл-документы. Ссылки на яндекс-диск и другие сервисы не принимаются. Все ссылки будут
          проверены, и если они не работают, тебе об этом скажут
        </div>

        <div className={style.line}>
          В качестве имени в боте можно использовать реальное имя или выдуманное, если хочешь сохранить интригу после объявления результатов
        </div>

        <div className={style.line}>Текст должен быть написан специально для движа по любому вашему сеттингу, ранее написанные тексты не принимаются</div>

        <div className={style.line}>Измените имена и названия в тексте для сохранения анонимности</div>

        <div className={style.line}>У текста должно быть любое название, не совпадающее с темой</div>

        <div className={style.line}>Объем текста должен быть от 1000 до 10 000 знаков (включительно, с учётом пробелов)</div>

        <div className={style.line}>
          Не запрещены оригинальные шрифты, оформление текста, в том числе иллюстрациями, но оценка должна выставляться только за содержание текста
        </div>

        <div className={style.line}>До окончания движа нужно сохранять анонимность</div>

        <div className={style.line}>Пропустившие сдачу работ будут исключены из движа и не участвуют в чтении и голосовании</div>

        <div className={style.line}>Пропустившие голосование будут исключены из движа и не получат свои результаты</div>

        <div className={style.line}>Если тексты набрали одинаковое количество баллов, побеждает тот, который короче</div>

        <div className={style.line}>Если тексты набрали одинаковое количество баллов и одинаковые по длине, побеждает тот, который был отправлен раньше</div>

        <h3>А что можно?</h3>

        <div className={style.line}>В тексте не должно быть материалов, нарушающих действующее законодательство</div>
        <div className={style.line}>Запрещены темы, подпадающие под законы об экстремизме</div>
        <div className={style.line}>Запрещены темы, связанные со слишком детальным описанием NSFW и сцен насилия</div>
        <div className={style.line}>Не приветствуются острополитические темы и, в целом, темы, основанные на новостной повестке IRL</div>
        <div className={style.line}>Мат не запрещён</div>

        <h3>А что делать?</h3>

        <div className={style.listItem}>1. Вступить в беседу ВК и познакомиться с остальными участниками</div>
        <div className={style.listItem}>2. Зарегистрироваться в боте и выбрать имя</div>
        <div className={style.listItem}>3. Написать текст и сдать при помощи бота ссылкой на гугл-документы</div>
        <div className={style.listItem}>
          4. Выбрать 5 текстов и указать 5 номеров понравившихся текстов. Нужно помнить, что их порядок имеет значение. Первый текст получает больше всего
          баллов, второй поменьше, а третий, четвёртый и пятый получают самое меньшее одинаковое число
        </div>
        <div className={style.listItem}>
          5. [дополнительно] Выставить дополнительные оценки на странице "Тексты". Оцениваются 4 критерия: общее впечатление, соответствие теме, читабельность и
          красота текста. После объявления результатов автору будет показана статистика, всем остальным — место в общем рейтинге по критерию
        </div>
        <div className={style.listItem}>
          6. [дополнительно] Выбрать номинации на странице "Номинации". После объявления результатов всем будет показано, какие тексты выиграли номинации
        </div>
        <div className={style.listItem}>
          7. [дополнительно] Написать рецензии на странице "Тексты". После объявления результатов только автору будут показаны рецензии
        </div>
        <div className={style.listItem}>
          8. [дополнительно] Сделать ставки на возможных победителей на странице "Ставки". Победители получат свои пчелокоины 🐝 и арбузокоины 🍉
        </div>
        <div className={style.listItem}>8. Ждать результатов</div>
      </div>
    </>
  );
};
