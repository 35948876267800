import { NominationItem, nominationsList } from '../../../../data/nominations';
import { NominationType } from '../../../../store/profile.slice';
import style from './AchievementsList.module.scss';
import { NominationBlock } from './NominationBlock';

interface Props {
  nominations: string[];
  nominationsWon: string[];
}
type Collector = {
  type: NominationType;
  count: number;
  item: NominationItem;
};

export const NominationsList = (props: Props) => {
  const collector: Collector[] = [];
  props.nominations.forEach((achievementType) => {
    const typeIndex = collector.findIndex((item) => item.type === achievementType);
    if (typeIndex !== -1) {
      collector[typeIndex].count += 1;
    } else {
      const nomination = nominationsList.find((ach) => ach.type === achievementType);
      if (nomination) {
        collector.push({
          type: achievementType as NominationType,
          count: 1,
          item: nomination,
        });
      }
    }
  });

  return (
    <div className={style.achievements}>
      {collector.map((nomination) => {
        const isWin = props.nominationsWon.includes(nomination.type);

        return <NominationBlock item={nomination.item} count={nomination.count} isWin={isWin} />;
      })}
    </div>
  );
};
