import React from 'react';
import { Text } from '../../types/types';
import style from './ReviewModal.module.scss';
import { postReviewApi } from '../../api/user.api';
import { useParams } from 'react-router-dom';
import { getTextsMethod } from '../../methods/app.methods';

interface Props {
  textIndex: number;
  texts: Text[];
  onChangeIndexCallback: (textIndex: number) => void;
}

export const ReviewModal = (props: Props) => {
  let { guid = '' } = useParams();
  const [reviewText, set_reviewText] = React.useState('');

  function onClickSend() {
    if (reviewText.trim().length === 0) return;

    postReviewApi({
      guid,
      textIndex: props.textIndex + 1,
      text: reviewText,
    }).then(() => {
      set_reviewText('');
      props.onChangeIndexCallback(-1);
      getTextsMethod(guid);
    });
  }

  function onClickCancel() {
    props.onChangeIndexCallback(-1);
  }

  return (
    <div className={style.reviewModal}>
      <div className={style.wrapper}>
        <div className={style.modal}>
          <h3>Рецензия на текст «{props.texts[props.textIndex].title}»</h3>
          <textarea value={reviewText} onChange={(event) => set_reviewText(event.target.value)}></textarea>
          <div className={style.informer}>Помни, что рецензия не должна содержать оскорблений и задевать автора. Будьте добрее, люди!!!</div>
          <button onClick={onClickSend}>Отправить 🥰</button>
          <button onClick={onClickCancel}>Отмена 🥺</button>
        </div>
      </div>
    </div>
  );
};
