import { fameUsers } from '../../../data/users';
import { pluralSets, pluralText } from '../../../methods/text.methods';
import { AchievementsList } from './components/AchievementsList';
import { NominationsList } from './components/NominationsList';
import style from './HallOfFame.module.scss';
import cn from 'classnames';

interface TextRowProps {
  cellData: [string, number, number?];
  index: number;
  place: number;
}

const TextRow = (props: TextRowProps) => {
  const textName = props.cellData[0];
  const balls = props.cellData[1];
  const place = props.cellData[2] || props.place + 1;

  if (textName === 'не участвует') {
    return <div className={style.noText}>★ Грелочка {props.index}: Не участвует ❌</div>;
  }

  if (textName === 'Участвует') {
    return <div className={style.noText}>★ Грелочка {props.index}: Участвует сейчас ⏳</div>;
  }

  return (
    <>
      <div className={style.textTitle}>
        ★ Грелочка {props.index}: «{textName}»: {pluralText(balls === -1 ? 0 : balls, pluralSets.balls)}
        {balls > 0 && place > 0 && (
          <div
            className={cn(style.badge, {
              [style.gold]: place === 1,
              [style.silver]: place === 2,
              [style.copper]: place === 3,
              [style.gray]: place > 3,
            })}
          >
            {place} место
          </div>
        )}
        {balls === 0 && <div className={cn(style.badge, style.black)}>Могила 💀</div>}
        {balls === -1 && <div className={cn(style.badge, style.black)}>Бан 💀</div>}
      </div>
    </>
  );
};

export const HallOfFame = () => {
  const fameUsersSorted = [...fameUsers];
  fameUsersSorted.sort(function (a, b) {
    const summA = a.round1[1] + a.round2[1] + a.round3[1] + a.round4[1] + a.round5[1];
    const summB = b.round1[1] + b.round2[1] + b.round3[1] + b.round4[1] + b.round5[1];
    if (summA > summB) return -1;
    if (summA < summB) return 1;
    return 0;
  });

  const fameUserSortedByRound1 = [...fameUsers];
  fameUserSortedByRound1.sort(function (a, b) {
    const valueA = a.round1[1];
    const valueB = b.round1[1];
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  const fameUserSortedByRound2 = [...fameUsers];
  fameUserSortedByRound2.sort(function (a, b) {
    const valueA = a.round2[1];
    const valueB = b.round2[1];
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  const fameUserSortedByRound3 = [...fameUsers];
  fameUserSortedByRound3.sort(function (a, b) {
    const valueA = a.round3[1];
    const valueB = b.round3[1];
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  const fameUserSortedByRound4 = [...fameUsers];
  fameUserSortedByRound4.sort(function (a, b) {
    const valueA = a.round4[1];
    const valueB = b.round4[1];
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  const fameUserSortedByRound5 = [...fameUsers];
  fameUserSortedByRound5.sort(function (a, b) {
    const valueA = a.round5[1];
    const valueB = b.round5[1];
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  return (
    <div className={style.hallOfFame}>
      {fameUsersSorted.map((user, index) => {
        const anyBan = user.round3[1] === -1;
        const summ = user.round1[1] + user.round2[1] + user.round3[1] + user.round4[1] + user.round5[1] + (anyBan ? 1 : 0);

        const isVeteran =
          user.round1[0] !== 'не участвует' &&
          user.round2[0] !== 'не участвует' &&
          user.round3[0] !== 'не участвует' &&
          user.round4[0] !== 'не участвует' &&
          user.round5[0] !== 'не участвует';

        const round1place = fameUserSortedByRound1.findIndex((_user) => _user.name === user.name);
        const round2place = fameUserSortedByRound2.findIndex((_user) => _user.name === user.name);
        const round3place = fameUserSortedByRound3.findIndex((_user) => _user.name === user.name);
        const round4place = fameUserSortedByRound4.findIndex((_user) => _user.name === user.name);
        const round5place = fameUserSortedByRound5.findIndex((_user) => _user.name === user.name);
        return (
          <div className={style.userRow} key={index}>
            <h3>
              #{index + 1}: {user.name}
            </h3>
            <div className={style.userGrid}>
              <div className={style.gridCell}>
                {isVeteran && <div className={cn(style.badge, style.purple)}>Ветеран всех грелочек!</div>}
                <TextRow index={1} cellData={user.round1} place={round1place} />
                <TextRow index={2} cellData={user.round2} place={round2place} />
                <TextRow index={3} cellData={user.round3} place={round3place} />
                <TextRow index={4} cellData={user.round4} place={round4place} />
                <TextRow index={5} cellData={user.round5} place={round5place} />
                <AchievementsList achievements={user.achievements} />
                {user.nominations.length > 0 && (
                  <>
                    <h3>Выигранные номинации:</h3>
                    <NominationsList nominations={user.nominations} nominationsWon={[]} />
                  </>
                )}
              </div>
              <div className={cn(style.gridCell, style.balls)}>
                <div>Всего баллов</div>
                <div className={style.ballsCount}>{summ}</div>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
    </div>
  );
};
