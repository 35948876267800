interface FameUser {
  name: string;
  round1: [string, number, number];
  round2: [string, number, number];
  round3: [string, number];
  round4: [string, number];
  round5: [string, number, number];
  achievements: string[];
  nominations: string[];
}

export const fameUsers: FameUser[] = [
  {
    name: 'Лучший Человек',
    round1: ['Лес', 0, 14],
    round2: ['Координатор', 0, 0],
    round3: ['Яблочный червь', 5],
    round4: ['Самурай', 2],
    round5: ['не участвует', 0, 0],
    achievements: ['dead', 'dead'],
    nominations: [],
  },
  {
    name: 'Аскарон Британия',
    round1: ['Замок', 26, 2],
    round2: ['Звёзды', 22, 2],
    round3: ['Дьявольская машина / Ставка', 18],
    round4: ['Новая жизнь', 9],
    round5: ['Вернуться обратно на землю / Шкатулка', 11, 6],
    achievements: [
      '2nd',
      'longest',
      '2nd',
      'longest',
      'longest',
      'longest',
      'graphoman',
      'graphoman',
      'veteran',
      'theme',
      'nominant10',
      'seasonwinner',
      'dominant',
      'fluffy',
      'betlose',
      'betlose',
    ],
    nominations: ['ogo'],
  },
  {
    name: 'Tamashi No-Kage',
    round1: ['Город (Погодный маг)', 2, 9],
    round2: ['Зеркальная западня', 12, 3],
    round3: ['не участвует', 0],
    round4: ['не участвует', 0],
    round5: ['не участвует', 0, 0],
    achievements: ['3rd'],
    nominations: [],
  },
  {
    name: 'Даша Клочкова',
    round1: ['Про Таню и ломбард', 3, 8],
    round2: ['Недоступное', 12, 4],
    round3: ['не участвует', 0],
    round4: ['Монетка поменьше', 9],
    round5: ['С гастролей', 4, 18],
    achievements: ['pass', 'readable', 'nominant10'],
    nominations: [],
  },
  {
    name: 'Васа Бессонова',
    round1: ['Абрахам и Мира', 1, 10],
    round2: ['не участвует', 0, 0],
    round3: ['Кровавое затмение', 0],
    round4: ['Ностальгия убивает', 7],
    round5: ['Все пошло наперекосяк', 0, 23],
    achievements: ['pass', 'lowhp', 'dead', 'shortest', 'potato', 'dead', 'betlose'],
    nominations: [],
  },
  {
    name: 'Ярослав Мелин',
    round1: ['Теорема Эскобара', 1, 12],
    round2: ['Цена магии', 10, 5],
    round3: ['Очевидное', 0],
    round4: ['не участвует', 0],
    round5: ['не участвует', 0, 0],
    achievements: ['lowhp', 'oversize', 'dead'],
    nominations: [],
  },
  {
    name: 'Лина Горностай',
    round1: ['Снежный', 0, 14],
    round2: ['Студенты и завод', 1, 10],
    round3: ['Замок', 0],
    round4: ['Чиновник', 3],
    round5: ['Глоток воздуха', 0, 25],
    achievements: ['dead', 'shortest', 'lowhp', 'dead', 'veteran', 'dead', 'betwin', 'betlose'],
    nominations: [],
  },
  {
    name: 'Дарья Калинина',
    round1: ['Крылья', 8, 15],
    round2: ['Крохотный комочек счастья', 2, 9],
    round3: ['Неизбежное', 5],
    round4: ['Я знала тебя другим', 9],
    round5: ['Благословение', 8, 8],
    achievements: ['shortest', 'veteran', 'betlose', 'betlose'],
    nominations: [],
  },
  {
    name: 'Алиса Ковалева',
    round1: ['Серая пустыня', 12, 5],
    round2: ['Призывательница', 0, 0],
    round3: ['Я лежала в темноте', 12],
    round4: ['Клятва', 6],
    round5: ['Не мы возвращаемся', 3, 20],
    achievements: ['dead', 'shortest', 'veteran', 'nominant10'],
    nominations: ['dark'],
  },
  {
    name: 'Vivian Kass',
    round1: ['Ленка', 31, 1],
    round2: ['Шер Вирин', 7, 6],
    round3: ['Фуфындезоксирибонуклеиновая кислота', 27],
    round4: ['Смыслы', 11],
    round5: ['Сообщение', 8, 11],
    achievements: ['1st', '1st', '3rd', 'dominant', 'veteran', 'no_nominations', 'betwin', 'betwin'],
    nominations: [],
  },
  {
    name: 'Анна Олейник',
    round1: ['Встреча на утёсе', 1, 11],
    round2: ['Ледяной плен', 5, 7],
    round3: ['Мышонок', 3],
    round4: ['не участвует', 0],
    round5: ['Зелёное', 6, 13],
    achievements: ['lowhp', 'oversize', 'pass', 'betlose', 'betlose'],
    nominations: [],
  },
  {
    name: 'Виктория Великанова',
    round1: ['Ямимаску', 13, 4],
    round2: ['не участвует', 0, 0],
    round3: ['не участвует', 0],
    round4: ['не участвует', 0],
    round5: ['не участвует', 0, 0],
    achievements: [],
    nominations: [],
  },
  {
    name: 'Софья Ферстина',
    round1: ['Волосы', 5, 6],
    round2: ['не участвует', 0, 0],
    round3: ['не участвует', 0],
    round4: ['не участвует', 0],
    round5: ['не участвует', 0, 0],
    achievements: [],
    nominations: [],
  },
  {
    name: 'Алиса Плис',
    round1: ['Встреча (отец)', 13, 3],
    round2: ['Часы', 22, 1],
    round3: ['Пан Воржишек', 20],
    round4: ['Наша битва будет легендарной', 10],
    round5: ['Письмо', 14, 3],
    achievements: ['1st', '3rd', '3rd', 'veteran', 'illustrator', '3rd', 'dominant', 'dominant', 'betlose', 'betlose'],
    nominations: [],
  },
  {
    name: 'Поля Землеройка-Кьютина',
    round1: ['Иштир Агаши', 4, 7],
    round2: ['не участвует', 0, 0],
    round3: ['Закрывая глаза', 19],
    round4: ['не участвует', 0],
    round5: ['не участвует', 0, 0],
    achievements: ['pass'],
    nominations: [],
  },
  {
    name: 'Слава Тринкет',
    round1: ['не участвует', 0, 0],
    round2: ['Спасибо, мистер Спринг!', 3, 8],
    round3: ['Найдёныш', 0],
    round4: ['Мама позвонила((', 8],
    round5: ['Рождество с крысами', 0, 23],
    achievements: ['dead', 'shortest', 'dead', 'betlose', 'betlose'],
    nominations: [],
  },
  {
    name: 'Александра Никифорова',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Как долго ты сможешь сопротивляться?', -1],
    round4: ['не участвует', 0],
    round5: ['не участвует', 0, 0],
    achievements: ['ban'],
    nominations: [],
  },
  {
    name: 'Олеся Пустотина',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Калокагатия', 0],
    round4: ['Плата за силу', 1],
    round5: ['Возвращение домой', 3, 19],
    achievements: ['dead', 'lowhp', 'betlose', 'other'],
    nominations: ['literally'],
  },
  {
    name: 'Евгения Бельская',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Я достал нож', 7],
    round4: ['не участвует', 0],
    round5: ['Добрый пастух', 20, 2],
    achievements: ['oversize', 'pass', '2nd', 'betlose'],
    nominations: ['interesting'],
  },
  {
    name: 'Екатерина Лондарева',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Чудище', 3],
    round4: ['Монетка побольше', 13],
    round5: ['Я не вернусь', 5, 15],
    achievements: ['2nd', 'betwin', 'betlose'],
    nominations: [],
  },
  {
    name: 'Light Lird',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Дракон', 6],
    round4: ['Врата', 4],
    round5: ['Мир еще не готов', 5, 14],
    achievements: ['fluffy', 'potato', 'nominant10', 'nominant20', 'betwin', 'betlose'],
    nominations: ['standup', 'chef'],
  },
  {
    name: 'Инна Бойко',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Кто угодно, но не я', 18],
    round4: ['Цель', 6],
    round5: ['Не опуская рук', 26, 1],
    achievements: ['oversize', 'longest', '1st', 'like', 'style', 'betlose', 'betlose'],
    nominations: ['nabokov'],
  },
  {
    name: 'Sad Storyteller',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Воля мира', 0],
    round4: ['Обещанная', 5],
    round5: ['Треснувший камень / Встреча', 8, 8],
    achievements: ['dead', 'graphoman', 'lowhp', 'nominant10', 'betwin'],
    nominations: [],
  },
  {
    name: 'Тигра Мазурецкая (Лобанова)',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Обоз', 1],
    round4: ['не участвует', 0],
    round5: ['Дорога домой', 5, 16],
    achievements: ['lowhp', 'pass'],
    nominations: [],
  },
  {
    name: 'Кристина Епишова',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Стена Слов', 22],
    round4: ['не участвует', 0],
    round5: ['не участвует', 0, 0],
    achievements: ['2nd'],
    nominations: [],
  },
  {
    name: 'Тоня Жужужа',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['Клининг', 2],
    round4: ['Телохранитель', 5],
    round5: ['Поцелуй весны', 3, 21],
    achievements: ['nominant10', 'betwin', 'betlose'],
    nominations: ['mimimi'],
  },
  {
    name: 'Латышка',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['не участвует', 0],
    round4: ['Сто блокнотов на пути к цветущим садам на Марсе', 30],
    round5: ['Сто лет, чтобы увидеть тебя дважды', 11, 5],
    achievements: ['1st', 'longest', 'style', 'nominant10'],
    nominations: ['light'],
  },
  {
    name: 'Lis Hemlock',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['не участвует', 0],
    round4: ['У всего есть цена', 6],
    round5: ['Родина', 4, 17],
    achievements: ['betlose'],
    nominations: [],
  },
  {
    name: 'Иннокентий Белых',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['не участвует', 0],
    round4: ['не участвует', 0],
    round5: ['Полное затмение', 8, 9],
    achievements: ['nominant10', 'betwin', 'betlose'],
    nominations: ['not_ready'],
  },
  {
    name: 'Мафия Маффин',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['не участвует', 0],
    round4: ['не участвует', 0],
    round5: ['Я могу уйти?', 13, 4],
    achievements: ['betlose', 'betlose'],
    nominations: [],
  },
  {
    name: 'Юлия Чатян (Ефимкина)',
    round1: ['не участвует', 0, 0],
    round2: ['не участвует', 0, 0],
    round3: ['не участвует', 0],
    round4: ['не участвует', 0],
    round5: ['Артефакт', 9, 7],
    achievements: ['betwin', 'betlose'],
    nominations: [],
  },
  // {
  //   name: 'Ева Фульмен',
  //   round1: ['не участвует', 0, 0],
  //   round2: ['не участвует', 0, 0],
  //   round3: ['не участвует', 0],
  //   round4: ['не участвует', 0],
  //   round5: ['не участвует', 0],
  //   achievements: [],
  //   nominations: [],
  // },
  // {
  //   name: 'Таисия Иванова',
  //   round1: ['не участвует', 0, 0],
  //   round2: ['не участвует', 0, 0],
  //   round3: ['не участвует', 0],
  //   round4: ['не участвует', 0],
  //   round5: ['не участвует', 0],
  //   achievements: [],
  //   nominations: [],
  // },
];
