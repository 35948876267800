import { VoteAdmin } from '../../../api/user.api';
import cn from 'classnames';
import { Text } from '../../../types/types';
import { GameState } from '../../../store/game.slice';

interface Props {
  votes: VoteAdmin[];
  gameState: GameState;
  texts: Text[];
}

export const Votes = (props: Props) => {
  const total = props.gameState.votes.total;
  const voted = props.gameState.votes.done;

  return (
    <div className="votes">
      <div className="votesTitleBig">
        {voted * 5} / {total * 5} голосов
      </div>
      <div className="votesTitleSmall">
        {voted} / {total} игроков
      </div>
      <table>
        <thead>
          <tr>
            <td>Кто</td>
            <td>Сколько</td>
            <td>Кому</td>
            <td>Текст</td>
          </tr>
        </thead>
        <tbody>
          {props.votes.map((vote) => {
            return (
              <tr className="vote">
                <td className="name">{vote.fromUser.name}</td>
                <td>
                  <span> ➔ </span>
                  <span
                    className={cn('rating', {
                      gold: vote.rating > 2,
                      orange: vote.rating === 2,
                      red: vote.rating === 1,
                    })}
                  >
                    {vote.rating}
                  </span>
                  <span> ➔ </span>
                </td>
                <td className="name">{vote.toUser.name}</td>
                <td>«{vote.toUser.textName}»</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
