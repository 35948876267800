import React from 'react';
import { ProfileState } from '../../store/profile.slice';
import { TextBlock } from './TextBlock';
import { Text } from '../../types/types';
import { ReviewModal } from './ReviewModal';

interface Props {
  texts: Text[];
  profile: ProfileState;
}

export const Texts = (props: Props) => {
  const [reviewTextIndex, set_reviewTextIndex] = React.useState(-1);

  function setReviewIndex(textIndex: number) {
    set_reviewTextIndex(textIndex);
  }

  return (
    <>
      {reviewTextIndex !== -1 && <ReviewModal textIndex={reviewTextIndex} texts={props.texts} onChangeIndexCallback={setReviewIndex} />}
      <div className="texts">
        {props.texts.map((text, index) => {
          const badges = props.profile.extraVotes.filter((vote) => vote.textIndex === index + 1);
          return (
            <TextBlock
              text={text}
              profile={props.profile}
              index={index + 1}
              key={index}
              badges={badges}
              canExtraVoting
              onChangeIndexCallback={setReviewIndex}
            />
          );
        })}
      </div>
    </>
  );
};
