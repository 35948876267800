export interface UserAdmin {
  id: number;
  name: string;
  status: ActionType;
}

export interface Text {
  link: string;
  title: string;
  isMine: boolean;
  characters: number;
  reviews: {
    my: number;
    total: number;
  };
}

export interface Rating {
  user_id: number;
  target_id: number;
  rating: number;
}

export enum ActionType {
  Greetings = 'greetings',
  AwaitName = 'await_name',
  AwaitText = 'await_text',
  AwaitStartVoting = 'await_start_voting',
  AwaitVoting = 'await_voting',
  AwaitEndVoting = 'await_end_voting',
  GameEnded = 'game_ended',
  Banned = 'banned',
}

export type ExtraVoteByType = {
  bad: number;
  neutral: number;
  good: number;
};

export interface TextAdmin extends Text {
  user_id: number;
  user: string;
  realUser: string;
  rating: number;
  status: ActionType;
  extraVotes: {
    [key: string]: ExtraVoteByType;
  };
  bets: {
    coin: Coin;
    amount: number;
  }[];
  nominations: string[];
  nominationsWon: string[];
  reviewsTexts: {
    userName: string;
    userRealName: string;
    text: string;
  }[];
  done: {
    votes: number;
    extraVotes: number;
    nominations: number;
    bets: number;
    reviews: number;
  };
  guesses: {
    correct: number;
    total: number;
  };
  guessesFrom: {
    correct: number;
    total: number;
  };
}

export type TextResult = {
  title: string;
  link: string;
  characters: number;
  rating: number;
  bets: {
    coin: Coin;
    amount: number;
  }[];
  nominations: string[];
  nominationsWon: string[];
  extraPlaces: {
    like: number;
    theme: number;
    readable: number;
    style: number;
  };
  guesses: {
    correct: number;
    total: number;
  };
};

export type Coin = 'beecoin' | 'watermeloncoin';

export type Guess = {
  textIndex: number;
  userName: string;
  samePlayers: number;
  totalPlayers: number;
};
