import { configureStore } from '@reduxjs/toolkit';
import { textsSlice } from './texts.slice';
import { profileSlice } from './profile.slice';
import { gameSlice } from './game.slice';
import { adminSlice } from './admin.slice';
import { resultsSlice } from './results.slice';
import { betsSlice } from './bets.slice';

export const grelkaStore = configureStore({
  reducer: {
    profile: profileSlice.reducer,
    texts: textsSlice.reducer,
    game: gameSlice.reducer,
    admin: adminSlice.reducer,
    results: resultsSlice.reducer,
    bets: betsSlice.reducer,
  },
  devTools: true,
});

export type GrelkaState = ReturnType<typeof grelkaStore.getState>;
export type AppDispatch = typeof grelkaStore.dispatch;
