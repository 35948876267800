import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Index } from './components/index/Index';
import { UserPage } from './components/pages/UserPage';
import './App.scss';
import { useOnload } from './hooks/useOnload';
import { Season1 } from './components/pages/seasons/Season1';
import { Invite } from './components/pages/invite/Invite';

function App() {
  useOnload(() => {
    var storedTheme = localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    if (storedTheme) document.documentElement.setAttribute('data-theme', storedTheme);

    var storedScreen = localStorage.getItem('screen');
    if (storedScreen) document.documentElement.setAttribute('data-screen', storedScreen);
  });

  return (
    <div className="App">
      <h1>❤️ Грелочка ❤️</h1>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/:guid" element={<UserPage />} />
          <Route path="/season1" element={<Season1 />} />
          <Route path="/invite" element={<Invite />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
