import { achievementsList, NominationItem } from '../../../../data/nominations';
import { NominationType } from '../../../../store/profile.slice';
import style from './AchievementsList.module.scss';
import { NominationBlock } from './NominationBlock';

interface Props {
  achievements: string[];
}
type CollectorItem = {
  type: NominationType;
  count: number;
  item: NominationItem;
};

export const AchievementsList = (props: Props) => {
  const collector: CollectorItem[] = [];
  props.achievements.forEach((achievementType) => {
    const typeIndex = collector.findIndex((item) => item.type === achievementType);
    if (typeIndex !== -1) {
      collector[typeIndex].count += 1;
    } else {
      const achObject = achievementsList.find((ach) => ach.type === achievementType);
      if (achObject) {
        collector.push({
          type: achievementType as NominationType,
          count: 1,
          item: achObject,
        });
      }
    }
  });

  return (
    <div className={style.achievements}>
      {collector.map((achievement) => {
        return <NominationBlock item={achievement.item} count={achievement.count} />;
      })}
    </div>
  );
};
