import React from 'react';
import { pluralSets, pluralText } from '../../../methods/text.methods';
import { GameState } from '../../../store/game.slice';
import { NominationsList } from './components/NominationsList';
import style from './Winners.module.scss';

interface Props {
  gameState: GameState;
}

export const Winners = (props: Props) => {
  const textsSorted = [...props.gameState.results];
  textsSorted.sort(function (a, b) {
    const ratingA = a.rating;
    const ratingB = b.rating;
    const charactersA = a.characters;
    const charactersB = b.characters;

    if (ratingA == ratingB) {
      return charactersA < charactersB ? -1 : charactersA > charactersB ? 1 : 0;
    } else {
      return ratingA > ratingB ? -1 : 1;
    }
  });

  return (
    <div className={style.winners}>
      <div className={style.texts}>
        {textsSorted.map((text, index) => {
          const beeCoins = text.bets.filter((bet) => bet.coin === 'beecoin').reduce((acc, bet) => acc + bet.amount, 0);
          const watermelonCoins = text.bets.filter((bet) => bet.coin === 'watermeloncoin').reduce((acc, bet) => acc + bet.amount, 0);
          const place = index + 1;
          const guessPercent = text.guesses.total > 0 ? Math.ceil((text.guesses.correct / text.guesses.total) * 100) : 0;

          return (
            <div className={style.textBlock} key={index}>
              <h2>«{text.title}»</h2>
              <div className={style.balls}>{place} место</div>
              <div>
                {pluralText(text.rating, pluralSets.balls)} | {pluralText(text.characters, pluralSets.characters)} | {beeCoins} 🐝 | {watermelonCoins} 🍉 |{' '}
                <a href={text.link} target="_blank" rel="noopener noreferrer">
                  Ссылка на текст
                </a>
              </div>
              <div className={style.extraPlaces}>
                <div>#{text.extraPlaces.like} место по оценке "Общее впечатление"</div>
                <div>#{text.extraPlaces.theme} место по оценке "Соответствие теме"</div>
                <div>#{text.extraPlaces.readable} место по оценке "Читабельность"</div>
                <div>#{text.extraPlaces.style} место по оценке "Красота текста"</div>
              </div>
              <div className={style.guesses}>
                Авторство угадали: {text.guesses.correct} из {text.guesses.total} ({guessPercent}%)
              </div>

              <div className={style.nominations}>
                <NominationsList nominations={text.nominations} nominationsWon={text.nominationsWon} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
