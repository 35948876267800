import { ResultsState } from '../../../store/results.slice';
import { pluralSets, pluralText } from './../../../methods/text.methods';
import { ExtraVoteBlock } from './components/ExtraVoteBlock';
import { NominationsList } from './components/NominationsList';
import style from './Results.module.scss';

interface Props {
  results: ResultsState;
}

export const MyResults = (props: Props) => {
  const { results } = props;
  const guessPercent = results.guesses.total > 0 ? Math.ceil((results.guesses.correct / results.guesses.total) * 100) : 0;
  const guessFromPercent = results.guessesFrom.total > 0 ? Math.ceil((results.guessesFrom.correct / results.guessesFrom.total) * 100) : 0;

  return (
    <div className={style.results}>
      <div>рассказ</div>
      <h2>«{results.title}»</h2>
      <div>набрал</div>
      <h2>{pluralText(results.rating, pluralSets.balls)}</h2>
      <div>и занял</div>
      <h2>{results.myPlace} место</h2>

      {props.results.myPlace === 1 && <div className={style.medal}>🥇</div>}
      {props.results.myPlace === 2 && <div className={style.medal}>🥈</div>}
      {props.results.myPlace === 3 && <div className={style.medal}>🥉</div>}

      <div className={style.separator} />
      <div>
        За него {pluralText(results.votesTotal, pluralSets.voteVerb, true)} {pluralText(results.votesTotal, pluralSets.people)}
      </div>
      <div className={style.separator} />
      <div>получил {pluralText(results.extraVotesTotal, pluralSets.extraVotesGen)}</div>
      <div className={style.separator} />
      <div>{pluralText(results.nominations.length, pluralSets.nominations)}</div>
      <div className={style.separator} />
      <div>{pluralText(results.reviews.length, pluralSets.reviews)}</div>

      <div className={style.separator} />

      <div>его длина — {pluralText(results.characters, pluralSets.characters)} </div>
      <div className={style.separator} />
      <div>он длиннее {pluralText(results.textsShorterMine, pluralSets.textsGen)} </div>
      <div className={style.separator} />
      <div>и короче {pluralText(results.textsLongerMine, pluralSets.textsGen)} </div>

      <div className={style.separator} />
      <div className={style.separator} />
      <div className={style.separator} />
      <h3>
        Его авторство угадало {results.guesses.correct} из {results.guesses.total} человек ({guessPercent}%)
      </h3>
      <h3>
        Ты угадал(а) {results.guessesFrom.correct} из {results.guessesFrom.total} авторов ({guessFromPercent}%)
      </h3>

      <div className={style.statsGrid4}>
        <ExtraVoteBlock type="like" extraVotes={results.extraVotes} place={props.results.extraPlace.like} />
        <ExtraVoteBlock type="theme" extraVotes={results.extraVotes} place={props.results.extraPlace.theme} />
        <ExtraVoteBlock type="readable" extraVotes={results.extraVotes} place={props.results.extraPlace.readable} />
        <ExtraVoteBlock type="style" extraVotes={results.extraVotes} place={props.results.extraPlace.style} />
      </div>

      <div className={style.separator} />

      {results.nominations.length > 0 && (
        <>
          <h2>{pluralText(results.nominations.length, pluralSets.nominations)}</h2>
          <NominationsList nominations={results.nominations} nominationsWon={results.nominationsWon} />
        </>
      )}

      <div className={style.separator} />

      {results.reviews.length > 0 && (
        <>
          <div className={style.reviews}>
            <h2>{pluralText(results.reviews.length, pluralSets.reviews)}</h2>
            {results.reviews.map((review, index) => {
              const rows = review.text.split('\n');
              return (
                <div className={style.review} key={index}>
                  {rows.map((row, index2) => {
                    return <div key={index2}>{row}</div>;
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}

      <div className={style.separator} />
      <div className={style.separator} />

      <h3>На твой текст поставили</h3>
      <div className={style.betsForMe}>
        <div>🐝{props.results.betsToMe.beecoin}</div>
        <div>🍉{props.results.betsToMe.watermeloncoin}</div>
      </div>
      {props.results.myPlace < 4 && <h2>И не зря!</h2>}

      <div className={style.separator} />
      <div className={style.separator} />

      {results.betsFromMe.length > 0 &&
        results.betsFromMe.map((bet, index) => {
          const pluralType = bet.coin === 'beecoin' ? pluralSets.beecoin : pluralSets.watermeloncoin;
          const coinIcon = bet.coin === 'beecoin' ? '🐝' : '🍉';
          if (bet.factor > 0) {
            const winCount = bet.amount * bet.factor;
            return (
              <div className={style.bets} key={index}>
                Твоя ставка {coinIcon}
                {bet.amount} на текст <span className={style.strong}>«{bet.textTitle}»</span> сработала ✕{bet.factor} и принесла тебе {coinIcon}
                {pluralText(winCount, pluralType)}
              </div>
            );
          } else {
            return (
              <div className={style.bets} key={index}>
                Твоя ставка на текст <span className={style.strong}>«{bet.textTitle}»</span> не сработала 😭
              </div>
            );
          }
        })}

      <div className={style.separator} />
      <div className={style.separator} />
      <div className={style.separator} />
      <div className={style.separator} />
      <div className={style.separator} />

      <h2>💛 Спасибо за участие 💛</h2>
    </div>
  );
};
